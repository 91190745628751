/*====================== COLORS =========================*/
$color-vtl-white: #ffffff;
$color-vtl-primary: #ba1100;
$color-vtl-secondary: #fca800;
$color-vtl-secondary-light: #fca8001a;
$color-vtl-background: #dadada;
$color-vtl-light: #f5f5f7;
$color-vtl-green: #21b59b;
$color-vtl-green-bg: #21b59b1a;
$color-vtl-dark: #282b3e;
$color-vtl-danger: #ee2737;
$color-vtl-warning: #ffc24b;
$color-vtl-placeholder: #8b8b8b;
$color-vtl-muted: #b8b8b8;
$color-vtl-border: #ededed;
$color-vtl-red: #ff0000;
$color-vtl-paper: #fafafa;
$color-vtl-addon: #ccccca;
$color-vtl-muted2: #989898;
$color-vtl-dropzone: #f7f7f7;
$color-vtl-disabled: #8D8D8D;

$spacer: 1.25rem;
//======================= SHADOWS =========================
$shadows: (
  1: '0 0 20px 0 rgba(0,0,0,0.05)',
  2: '0 3px 6px -4px rgba(0,0,0,0.04)',
  3: '0 0 50px 10px rgba(0,0,0,0.03)',
  4: '0px 5px 30px rgba(0, 0, 0, 0.08)',
);
