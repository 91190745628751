@import 'modules/forms';
@import 'modules/data-table';
@import 'modules/dropdown';
@import 'modules/filter';
@import 'modules/dashboard';
@import 'modules/details';
@import 'modules/login';
@import 'modules/cards-list';
@import 'modules/phone-input';
@import 'modules/toast';
@import 'modules/modal';
@import 'modules/dropzone';
@import 'modules/product-category';
@import 'modules/products-list';
@import 'modules/stores-menu';
@import 'modules/variations';
@import 'modules/timepicker';
@import 'modules/gmaps';
@import 'modules/delivery';
@import 'modules/build-item';
@import 'modules/navs';

@import '~rc-time-picker/assets/index.css';
