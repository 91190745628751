.vtl-timepicker {
  display: flex;
  align-items: center;
  border: 1px solid $color-vtl-border;
  padding: rfs-value(2.5px) rfs-value(10px) rfs-value(2.5px) rfs-value(5px);
  border-radius: rfs-value(4px);
  cursor: pointer;

  .rc-time-picker-clear {
    display: none;
  }

  .rc-time-picker-input {
    border: none;
    color: $color-vtl-dark;
    font-size: rfs-value(14px);
    padding: rfs-value(5px);
    cursor: pointer;
    &:disabled {
      background-color: $color-vtl-white;
    }
  }
}

.vtl-timepicker-popup {
  z-index: 9999;
  .rc-time-picker-panel-inner {
    position: relative;
    border-radius: rfs-value(8px);
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
    border: none;
    padding: rfs-value(30px) rfs-value(15px);
    margin-top: rfs-value(42px);

    .rc-time-picker-panel-input-wrap {
      display: none;
    }

    .rc-time-picker-panel-combobox {
      display: flex;

      .rc-time-picker-panel-select {
        border: none;
        width: auto;

        &:nth-child(1) {
          margin-right: rfs-value(20px);
          position: relative;
          overflow: visible;

          &::before {
            content: ":";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: rfs-value(66px);
            font-size: rfs-value(24px);
            @include fontAvertaSemibold;
          }
        }

        &:nth-child(1),
        &:nth-child(2) {
          ul>li[role="button"] {
            font-size: rfs-value(25px);
            color: $color-vtl-dark;
            padding: rfs-value(10px);
            line-height: unset;
            height: auto;
            width: rfs-value(60px);
            background-color: $color-vtl-paper;
            border: 1px solid $color-vtl-border;
            border-radius: rfs-value(2px);
            @include fontAvertaSemibold;
            text-align: center;

            &:not(.rc-time-picker-panel-select-option-selected) {
              display: none;
            }
          }

          &.rc-time-picker-panel-select-active {
            ul>li[role="button"] {
              background-color: rgba(255, 0, 0, 0.1);
              color: $color-vtl-red;
              border-color: $color-vtl-red;
            }
          }
        }

        &:nth-child(3) {
          margin-left: rfs-value(10px);
          ul>li[role="button"] {
            text-transform: uppercase;
            font-size: rfs-value(12px);
            line-height: normal;
            padding: rfs-value(6.5px) rfs-value(10px);
            height: auto;
            text-align: center;
            @include fontAvertaSemibold;
            color: $color-vtl-border;
            background-color: $color-vtl-paper;
            border: 1px solid $color-vtl-border;

            &:nth-child(1) {
              border-radius: rfs-value(2px) rfs-value(2px) 0 0;
            }

            &:nth-child(2) {
              border-radius: 0 0 rfs-value(2px) rfs-value(2px);
            }

            &.rc-time-picker-panel-select-option-selected {
              background-color: $color-vtl-red;
              border-color: $color-vtl-red;
              color: $color-vtl-white;
            }
          }
        }
      }
    }

    // Arrow controls
    .controls {
      button {
        position: absolute;

        &.up {
          top: rfs-value(8px);
        }

        &.down {
          bottom: rfs-value(8px);
        }
      }

      &.hours {
        button {
          left: rfs-value(40px);
        }
      }

      &.minutes {
        button {
          left: rfs-value(120px);
        }
      }
    }
  }
}