/* hides google recaptcha badge */
.grecaptcha-badge { 
    visibility: hidden;
}

.gmnoprint a, .gmnoprint span {
    display:none;
}
.gmnoprint div {
    background:none !important;
}

.gm-style-cc:last-child {
    display: none !important;
}

a[title="Report errors in the road map or imagery to Google"] {
    display: none !important;
}