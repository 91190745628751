//Nav styles
.nav.nav-pills {
	display: inline-flex;
	border-radius: _rem(22);
	background-color: $color-vtl-white;
	padding: _rem(5);
	margin-bottom: _rem(20);
	margin-right: auto;

	@include media-breakpoint-down(md) {
		padding: 0 _rem(5) _rem(5) _rem(5);
		justify-content: center;
	}

	.nav-item {
		min-width: _rem(80);

		.nav-link {
			color: $color-vtl-placeholder;
			@include fontAvertaRegular();
			justify-content: center;
			cursor: pointer;
			padding: _rem(5) _rem(18);
			border-radius: _rem(20);
			text-decoration: none !important;
		}

		.nav-link.active {
			background-color: $color-vtl-primary;
			color: $color-vtl-white;
			@include fontAvertaSemibold();
		}

		+ .nav-item {
			margin-left: _rem(5);
		}
	}

	@include media-breakpoint-down(md) {
		.nav-item {
			margin-top: _rem(5);
			margin-right: _rem(15);
			min-width: _rem(100);

			&:last-child {
				margin-right: 0;
			}

			.nav-link {
				padding: _rem(5) _rem(10);
				font-size: _rem(12);
			}

			+ .nav-item {
				margin-left: 0;
			}
		}
	}
}