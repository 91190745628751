//Details page
.details-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: rfs-value(100px);

  @include media-breakpoint-up(lg) {
    max-height: 100vh;
    overflow: hidden auto;
    padding-bottom: rfs-value(150px);
  }

  .top-block {
    flex: 0 1 auto;
    border-bottom: 0.64px solid $color-vtl-border;

    .action-buttons {
      > * {
        margin-left: _rem(15);

        &:first-child {
          margin-left: 0;
        }
      }

      a {
        text-decoration: none;
      }
    }
  }

  //Top block (with avatar and actions)
  .details-header {
    padding: _rem(40) _rem(35) 0 _rem(35);

    @media (max-width: 1340px) {
      padding-left: _rem(15);
      padding-right: _rem(15);
    }

    @include media-breakpoint-down(md) {
      padding: _rem(5) _rem(15) 0 _rem(15);

      h3 {
        font-size: _rem(20);
      }
    }

    &.job-details-header {
      @include media-breakpoint-down(md) {
        padding-top: _rem(10);
      }

      .title-block {
        .MuiButtonBase-root {
          min-width: auto;
        }
      }
    }

    .title-block {
      padding-bottom: _rem(45);

      @include media-breakpoint-down(lg) {
        padding-bottom: _rem(20);      
      }

      .details-header-part {
        .editable-avatar {
          position: relative;

          .edit-icon-button {
            position: absolute;
            right: 0;
            bottom: 0;

            .MuiSvgIcon-root {
              width: 12px;
              height: 12px;
            }
          }

          .delete-icon-button {
            position: absolute;
            display: none;
            top: 0;
            left: 0;

            .MuiSvgIcon-root {
              width: 12px;
              height: 12px;
            }
          }
          &:hover, &:focus {
            .delete-icon-button {
              display: inline-block;
            }
          }
        }

        .information-part {
          padding-left: _rem(20);

          .social-links-toolbar {
            margin-left: _rem(10);

            .social-link {
              margin: 0 _rem(4);
            }
          }

          .subelements {
            padding-top: _rem(5);

            .uid {
              padding-right: _rem(7);
            }
          }

          @include media-breakpoint-down(md) {
            padding: _rem(15) 0;
          }
        }
      }
    }

    .MuiTabs-root {
      margin-bottom: 0;
    }

    .user-details-head-row {
      margin: 0 _rem(-10);
      .user-details-head-item {
        display: flex;
        align-items: center;
        margin: 0 _rem(10);

        button, span {
          font-size: _rem(14) !important;
        }
      }

      @include media-breakpoint-down(md) {
        .user-details-head-item + .user-details-head-item {
          padding-top: _rem(10);
          padding-left: _rem(5);
        }
      }
    }
  }

  //Tabs content
  .content-block {
    height: 100%;
    padding: _rem(40) _rem(35);

    @media (max-width: 1340px) {
      padding: _rem(40) _rem(15) _rem(30) _rem(15);
    }

    @include media-breakpoint-down(md) {
      padding: _rem(24) 0;
    }
  }

  .reference-available {
    .MuiFormGroup-root {
      .MuiFormControlLabel-root {
        margin-bottom: 0;
      }
    }
  }

  .week-availability-item {
    padding: 0 _rem(10) 0 0;

    @include media-breakpoint-down(md) {
      padding: 0 0 _rem(10) 0;
    }
  }

  //Tab content fade effect
  .tab-content {
    .tab-pane.active {
      animation-name: fadeIn;
      animation-duration: 300ms;
      animation-timing-function: linear;
    }
  }

  &.product {
    .content-block {
      padding: _rem(40) _rem(35) _rem(50);

      @media (max-width: 1340px) {
        padding-left: _rem(15);
        padding-right: _rem(15);
      }
      
      @media (max-width: 1200px) {
        padding: _rem(40) _rem(16) _rem(50);
      }

      .details-card.variants {
        @include media-breakpoint-up(lg) {
          margin-top: _rem(20);
        }
      }

      .product-variant {
        padding-bottom: _rem(26);
        margin-bottom: _rem(20);
        border-bottom: 1px solid $color-vtl-border;
      }
    }
  }
}

div[role="tabpanel"] {
  height: 100%;
  > .container {
    height: 100%;
    @include media-breakpoint-up(lg) {
      margin-left: 0;
    }
  }
}