/* Dashboard */
.dashboard-wrapper {
  padding: _rem(40) _rem(35);
  width: 100%;
  @include media-breakpoint-up(lg) {
    flex: 1 0;
    max-height: 100vh;
    overflow: hidden scroll;

    .container-fluid {
      height: 100%;
    }
  }

  @media (max-width: 1340px) {
    padding: _rem(40) _rem(15);
  }

  @include media-breakpoint-down(md) {
    padding: 0 0 _rem(34) 0;
  }

  .header-row {
    padding-bottom: _rem(14);

    @include media-breakpoint-down(lg) {
      margin-top: _rem(-22);
      h3 {
        font-size: _rem(20);
        @include fontAvertaSemibold();
      }
    }

    .dashboard-btn {
      min-width: _rem(151);
    }
  }

  &.job-list {
    .header-row {
      padding-top: _rem(8);
      padding-bottom: _rem(40);
    }

    @include media-breakpoint-down(md) {
      padding-top: 0;

      .header-row {
        padding-top: 0;
      }
    }
  }

  .columns-content-block {
    margin-left: _rem(-10);
    margin-right: _rem(-10);

    table {
      td {
        padding-top: _rem(5);
        padding-bottom: _rem(5);
        vertical-align: middle;
      }

      @include media-breakpoint-up(lg) {
        td,
        th {
          padding: _rem(7) _rem(18);
        }
      }
    }
  }

  .preview {
    width: rfs-value(35px);
    min-width: rfs-value(35px);
    height: rfs-value(35px);
    object-fit: cover;

    &.shadow-sm {
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);
    }
  }
  
  @include media-breakpoint-up(lg) {
    &.tabs-contained {
      padding: 0;
      margin: 0 0 0 _rem(-15);

      .dashboard-content {
        padding: 0 _rem(50) _rem(35) _rem(50);
      }
    }
  }

  &.settings-list {
    .title {
      @include fontAvertaBold();

      @include media-breakpoint-down(lg) {
        @include fontAvertaSemibold();
        margin-bottom: _rem(24);
      }
    }

    @include media-breakpoint-down(lg) {
      padding: 0 0 _rem(90) 0;
    }

    .card {
      .card-body {
        img {
          width: _rem(50);
          height: _rem(50);
        }

        padding-top: _rem(40);

        @include media-breakpoint-down(lg) {
          padding: _rem(16);
          img {
            width: _rem(40);
            height: _rem(40);
            margin-right: _rem(16);
          }

          h4 {
            font-size: _rem(14);
          }
        }
      }
    }
  }

  &.fees {
    padding: _rem(20);
    @include media-breakpoint-up(lg) {
      padding: _rem(40) _rem(50);
    }
  }

  &.checkout-list {
    .header-row {
      padding-bottom: _rem(40);
    }

    @include media-breakpoint-down(lg) {
      padding-left: 0;
      padding-right: 0;

      .header-row {
        padding-bottom: _rem(30);
      }
    }

    .checkout-list {
      margin-top: _rem(14);
      @include media-breakpoint-up(lg) {
        margin-top: _rem(23);
      }
    }
  }
}
