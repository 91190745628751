.MuiTable-root {
  &.table-variations {
    border-collapse: separate;
    border-spacing: 0 rfs-value(8px);

    .MuiTableHead-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          padding: 0 rfs-value(15px) rfs-value(2px) rfs-value(15px);
          @include media-breakpoint-up(lg) {
            &:nth-child(1), 
            &:nth-child(2) {
              width: 30%;
            }
          }
        }
      }

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          padding: rfs-value(15px);
          background-color: #fafafa;
          &:first-child {
            @include media-breakpoint-up(lg) {
              border-radius: rfs-value(8px) 0 0 rfs-value(8px);
            }
          }

          &:last-child {
            @include media-breakpoint-up(lg) {
              border-radius: 0 rfs-value(8px) rfs-value(8px) 0;
            }
          }
        }

        @include media-breakpoint-down(lg) {
          display: flex;
          flex-direction: column;
          border-radius: rfs-value(4px);
          background-color: #fafafa;
          padding: rfs-value(10px) 0;

          + .MuiTableRow-root {
            margin-top: rfs-value(10px);
          }

          .MuiTableCell-root {
            background-color: transparent;
            position: relative;
            padding: rfs-value(10px);
            padding-left: 50%;

            &:before {
              content: attr(data-title);
              position: absolute;
              left: rfs-value(10px);
              top: rfs-value(10px);
              color: $color-vtl-muted;
              font-size: rfs-value(12px);
            }
          }
        }
      }
    }
  }
}
