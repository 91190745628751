// Dropzone styles
.dropzone {
  margin-bottom: 0;
  .preview-wrapper {
    border-radius: rfs-value(4px);
    position: relative;
    overflow: hidden;

    .actions {
      position: absolute;
      top: rfs-value(20px);
      right: rfs-value(20px);
      display: none;
    }

    &:hover {
      &.editable {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: linear-gradient(
            180deg,
            rgba(40, 43, 62, 0.5) 0%,
            rgba(40, 43, 62, 0) 100%
          );
        }

        .actions {
          display: flex;
        }
      }
    }
  }

  .longer-preview {
    height: rfs-value(550px);
    width: rfs-value(250px);
  }

  .dropzone-field {
    padding: rfs-value(45px) rfs-value(10px);
    border: 1px dashed $color-vtl-muted;
    border-radius: rfs-value(8px);
    cursor: pointer;

    @include media-breakpoint-down(md) {
      padding: rfs-value(20px) rfs-value(10px);
    }

    &.compact {
      padding: rfs-value(5px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: rfs-value(4px);
      border: 1px solid $color-vtl-border;
    }
  }

  .preview {
    height: rfs-value(200px);
    width: 100%;
    object-fit: contain;
    overflow: hidden;

    &.compact {
      object-fit: fill;
      border-radius: rfs-value(4px);
      border: 1px solid $color-vtl-dark;
    }

    &.preview-border {
      border: rfs-value(1.5px) solid rgba($color: #b8b8b8, $alpha: 0.5);
    }
  }

  .app-icon {
    height: 50px;
    width: 50px;
    object-fit: cover;
    overflow: hidden;

    &.compact {
      border-radius: rfs-value(4px);
      border: 1px solid $color-vtl-dark;
    }
  }

  &.fill-background {
    .dropzone-field {
      padding: rfs-value(34px) rfs-value(32px);
      background-color: $color-vtl-dropzone;
    }
  }
}

.image-fit {
  width: 100% !important;
}

.img-tall-fit {
  height: 100% !important;
  object-fit: fill !important;
}
