//Get relative size
@function _rem($px-value, $root-em: 1rem) {
  @return rfs-value($px-value + 'px');
}

@mixin boxShadow($variant) {
  box-shadow: #{map-get($shadows, $variant)};
  -webkit-box-shadow: #{map-get($shadows, $variant)};
  -moz-box-shadow: #{map-get($shadows, $variant)};
}

@mixin fontAvertaLight() {
  font-family: 'Averta Light' !important;
  font-weight: 300;
}

@mixin fontAvertaRegular() {
  font-family: 'Averta Regular' !important;
  font-weight: normal;
}

@mixin fontAvertaSemibold() {
  font-family: 'Averta Semibold' !important;
  font-weight: bold;
}

@mixin fontAvertaBold() {
  font-family: 'Averta Bold' !important;
  font-weight: bolder;
}

@mixin fontAvertaItalic() {
  font-family: 'Averta Regular Italic' !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin hover() {
  &:hover {
    @content;
  }
}

@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active() {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin multiline-overflow($lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
