html,
body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  @include fontAvertaRegular();

  * {
    font-family: unset;
  }

  div {
    &.border-top,
    &.border-bottom {
      border-color: !important;
    }
  }

  .font-weight-bold {
    @include fontAvertaSemibold();
  }

  .font-green {
    color: $color-vtl-green;
  }

  .font-red {
    color: $color-vtl-red;
  }

  .text-muted {
    color: $color-vtl-muted !important;
  }
}

hr {
  background-color: $color-vtl-border;
  opacity: 1;
}

p {
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}

button.unstyled-button {
  padding: 0;
  border: none;
  background: none;
}

.btn-outline-success {
  background-color: $color-vtl-green-bg;
  &:hover {
    color: $color-vtl-white;
  }
}

#root,
.react-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.card.details-card {
  border: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
  border-radius: _rem(8);
  background-color: #fff;

  > .card-body {
    padding: _rem(30);
  }

  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;

    .card-body {
      padding: _rem(16);
    }
  }

  .card-title {
    @include media-breakpoint-down(lg) {
      font-size: _rem(16);
    }
  }

  &.notifications {
    .card-body {
      @include media-breakpoint-down(md) {
        padding: _rem(30) _rem(10);
      }
    }
  }
}

.MuiTabs-scroller {
  &::-webkit-scrollbar {
    display: none;
  }
}

.add-new-link {
  color: $color-vtl-green;
  @include fontAvertaSemibold();
  font-size: _rem(12);
  line-height: _rem(15);
  text-decoration: none;
  border: none;
  background: none;
  padding: 0;

  @include hover-focus {
    color: $color-vtl-green;
  }
}

.card-icon {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.modal-icon {
    display: flex;
    padding-left: 0 !important;
    width: _rem(40);
    height: _rem(19);

    &.mastercard {
      width: _rem(25);
    }
  }
}

//Popover style
.popover.hint-popover {
  box-shadow: #{map-get($shadows, 1)};
  border-radius: _rem(4);
  background-color: $color-vtl-border;
  margin-right: _rem(6);
  z-index: 1300;

  .popover-body {
    padding: _rem(10) _rem(15);
    color: $color-vtl-muted;
  }
}

.font-weight-bolder {
  @include fontAvertaBold();
}

.font-weight-regular {
  @include fontAvertaRegular();
  font-weight: normal !important;
}

.font-italic {
  @include fontAvertaItalic();
}

.sr-only {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.rounded-sm {
  border-radius: _rem(4) !important;
}

b {
  @include fontAvertaSemibold();
}

.bg-graylight {
  background-color: $color-vtl-background;
}

.green-point-icon {
  &:before {
    content: '\2022';
    color: $color-vtl-green;
    font-size: _rem(18);
    line-height: 1;
  }
}

.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
}

.MuiDrawer-root {
  position: fixed;
  z-index: $zindex-popover;
}

.d-table {
  border-collapse: collapse;
}

.ellipsis-2 {
  @include multiline-overflow(2);
}

.ellipsis-3 {
  @include multiline-overflow(3);
}

.d-table-caption {
  display: table-caption;
}

.min-cropped-screen {
  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - 223px);
  }
}
